import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Layout,
} from '../components';
import locationsStyles from './styles/locations.module.scss';
import { generateServiceCityLinkData } from '../helpers';

const LocationsPage = ({ data }) => {
    const {
        locationPages,
    } = data.graphCMS;

    const { locationLink } = locationsStyles;

    const metaTitle = 'Locations | Wikilawn';
    const metaDescription = 'All the locations where Wikilawn provides helpful lawn care tips and guides.';
    const locationPagesOrderedByCityName = _.sortBy(locationPages, ({ city }) => {
        return city.name;
    });

    return (
        <Layout
            metaTitle={metaTitle}
            metaDescription={metaDescription}
        >
            <h1>Locations</h1>
            <div>
                {_.map(locationPagesOrderedByCityName, ({ city, service }) => {
                    const { to, linkText } = generateServiceCityLinkData({ city, service });
                    return (
                        <Link
                            key={to}
                            to={to}
                            data-testid={to}
                            className={locationLink}
                        >
                            {linkText}
                        </Link>
                    );
                })}
            </div>
        </Layout>
    );
};

export const pageQuery = graphql`
    {
        graphCMS {
            locationPages(where: {city: {slug_not: ""}, service: {slug_in: "lawn-care"}}) {
                service {
                    slug
                }
                city {
                    slug
                    name
                    state {
                        slug
                    }
                }
            }
        }
    }
`;

LocationsPage.propTypes = {
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            locationPages: PropTypes.arrayOf(
                PropTypes.shape({
                    city: PropTypes.shape({
                        slug: PropTypes.string,
                        name: PropTypes.string,
                        state: PropTypes.shape({
                            slug: PropTypes.string,
                        }),
                    }),
                    service: PropTypes.shape({
                        name: PropTypes.string,
                    }),
                }),
            ),
        }),
    }).isRequired,
};

export default LocationsPage;
